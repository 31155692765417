import { useState, memo } from "react"
import { InlineWidget } from "react-calendly"
import { MdKeyboardDoubleArrowUp, MdKeyboardArrowDown, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md"
import { FaArrowRight } from "react-icons/fa"
import { MdArrowBackIos } from "react-icons/md"
import { useNavigate } from "react-router-dom"


export const BackToTop = memo(({ padding = "50px", ...props }) => {
  return (
    <div style={{
      position: "sticky",
      bottom: 0,
      padding: padding,
      width: "100%",
      zIndex: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      backgroundColor: "transparent",
    }}>
      <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth", })} className="GreyHover">
        Top&nbsp;<MdKeyboardDoubleArrowUp />
      </button>
    </div >
  )
})


export const DropdownMenu = ({
  options = Array({ name: null, description: null, }),
  defaultValue = null,
  width = "300px",
  index, setIndex,
  disabled = false,
  styleProps = {},
  disabledOptions = [], disabledMessage = null,
  ...props
}) => {
  const [displayDropdownMenu, setDisplayDropdownMenu] = useState(false)

  return (
    <div
      className={displayDropdownMenu ? "dropdown is-active" : "dropdown"}
      style={{ width: width, maxWidth: width, minWidth: width, ...styleProps, }}>
      <div className="dropdown-trigger">
        <button className="button" aria-disabled={disabled} aria-controls="dropdown-menu" style={{ width: width, maxWidth: width, minWidth: width }} aria-haspopup="true" onClick={e => { e.preventDefault(); setDisplayDropdownMenu(!displayDropdownMenu) }} >
          {index !== null ? options[index]["name"] : defaultValue ? defaultValue : <>Select&nbsp;<MdKeyboardArrowDown /></>}
        </button>
      </div>

      {displayDropdownMenu &&
        <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ width: "100%" }}>
          <div className="dropdown-content" style={{ width: "100%" }}>
            {
              options.map((item, i) => {
                var disabled = Boolean(disabledOptions.length > 0 && disabledOptions.find(item => item === i))
                return (
                  <button
                    key={i} disabled={disabled} className={i === index ? "dropdown-item is-active" : disabled ? "dropdown-item has-background-grey-lighter" : "dropdown-item"}
                    onClick={e => { e.preventDefault(); setIndex(i); setDisplayDropdownMenu(false); }}
                  >
                    {disabled && disabledMessage ? `${item.name} - ${disabledMessage}` : item.name}
                  </button>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  )
}


export const Tag = ({ category, item, color = "is-primary is-light", button = null, width = "360px", ...props }) => {
  return (
    <span className={`tag ${color} is-medium`} style={{ margin: "5px" }}>
      {item.toLowerCase()}
      {button ? button : <button className="delete is-small"></button>}
    </span>
  )
}


export const Select = ({ options = [], currentSelectedItem, width = "350px", ...props }) => {
  return (
    <select
      style={{ width: width, minWidth: width, maxWidth: width }}
      onClick={e => {
        if (options[e.target.selectedIndex] !== undefined) {
          currentSelectedItem.current = options[e.target.selectedIndex]["value"]; if (props.callBack) { props.callBack() }
        }
      }}
    >
      {options.map((item, i) => { return (<option key={i}>{item["title"]}</option>) })}
    </select >
  )
}


export const NewsBar = ({ newsTitle, link, fontSize = "16px" }) => {
  return (
    <div className="GreyHover" style={{ border: "solid 1px grey", borderRadius: "20px", padding: "5px 10px" }}>
      <p style={{ fontSize }}>
        📣 {newsTitle} {link &&
          <a href={link} aria-label="Explore" target="_blank" rel="noreferrer">
            <FaArrowRight />
          </a>
        }
      </p>
    </div>
  )
}


export const PaginationNav = ({ paginationItems = [], initPageId = 0, paginationDisabled, itemWidth = "150px", ...props }) => {
  const [currentPageId, setCurrentPageId] = useState(initPageId)

  return (
    <nav className="pagination is-centered" role="navigation" aria-label="pagination">
      <button
        className="pagination-previous" disabled={currentPageId === 0 || currentPageId === null || paginationDisabled}
        onClick={e => {
          e.preventDefault(); setCurrentPageId(currentPageId - 1);
          if (paginationItems[currentPageId - 1].link) { window.location.href = paginationItems[currentPageId - 1].link }
        }}
        style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginRight: props.margin ? props.margin : "100px" }}
      >
        <MdKeyboardDoubleArrowLeft />
      </button>

      <ul className="pagination-list" style={{ position: "relative" }}>
        {paginationItems.map((item, i) => {
          const { title, link } = item
          return (
            <li key={i} style={{ minWidth: itemWidth, maxWidth: itemWidth }}>
              <button
                className="pagination-link" style={{ height: "30px", minHeight: "30px", maxHeight: "30px", width: "30px", minWidth: "30px", maxWidth: "30px", borderRadius: "60px", backgroundColor: i === currentPageId ? "#B9005B" : "#fafafa", color: i === currentPageId ? "#fafafa" : undefined }}
                aria-label={item}
                onClick={e => {
                  e.preventDefault();
                  currentPageId < paginationItems.length ? setCurrentPageId(i) : setCurrentPageId(0);
                  if (link) { window.location.href = link }
                }}
                disabled={paginationDisabled}
              >
                {i + 1}
              </button>
              <p style={{ fontSize: "12px", marginTop: "10px", color: i === currentPageId ? "#B9005B" : undefined }}>{title}</p>
            </li>
          )
        })}
      </ul>

      <button
        className="pagination-next" disabled={currentPageId >= paginationItems.length - 1 || paginationDisabled}
        onClick={e => {
          e.preventDefault(); setCurrentPageId(currentPageId + 1);
          if (paginationItems[currentPageId + 1].link) { window.location.href = paginationItems[currentPageId + 1].link }
        }}
        style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginLeft: props.margin ? props.margin : "100px" }}
      >
        <MdKeyboardDoubleArrowRight />
      </button>

    </nav >
  )
}


export const Modal = ({ withHeader = true, displayModal, setDisplayModal, children = null, title = "Select a timeslot", width = "90%" }) => {
  return (
    <div className={displayModal ? "modal is-active" : "modal"} style={{ backgroundSize: "cover" }}>
      <div className="modal-background"></div>
      <div className="modal-card" style={{ width }}>
        {withHeader &&
          <header className="modal-card-head">
            <p className="modal-card-title" style={{ fontSize: "18px" }}>{title}</p>
            <button className="delete" aria-label="close" onClick={() => { setDisplayModal(false); }}></button>
          </header>
        }
        <section className="modal-card-body" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {children
            ? children :
            <InlineWidget
              url="https://calendly.com/kuriko-i/custom-demo?hide_event_type_details=1&hide_gdpr_banner=1"
              styles={{ marginTop: "30px", width: "500px", height: "600px" }}
            />
          }
        </section>
        <footer></footer>
      </div>
    </div>
  )
}


export const BackToPrevPage = ({ pageName = "previous page", ...props }) => {
  const navigate = useNavigate()
  return (
    <button onClick={() => navigate(-1, { state: { ...props?.state } })} style={{ ...props?.style }}>
      <MdArrowBackIos />
      {/* &nbsp;Back to {pageName} */}
    </button>
  )
}
