import React, { memo } from "react"
import { FaHubspot, FaSalesforce, FaFacebook, FaLinkedin, FaGoogleDrive, FaMailchimp, FaTwitter, FaFileExcel } from "react-icons/fa"
import { SiAirtable, SiGmail } from "react-icons/si"

var mobileMode = Boolean(window.innerWidth <= 430)

export const DataPipelines = [
  {
    id: "hubspot",
    name: "Hubspot",
    logo: <FaHubspot />,
    ui: "",
    disabled: true,
  },
  {
    id: "salesforce",
    name: "Salesforce",
    logo: <FaSalesforce />,
    ui: "",
    disabled: true,
  },
  {
    id: "airtable",
    name: "Airtable",
    logo: <SiAirtable />,
    disabled: true,
  },
  {
    id: "googlesheets",
    name: "Google Sheet",
    logo: <FaGoogleDrive />,
    disabled: true,
  },
  {
    id: "excelsheet",
    name: "CSV/Excel",
    logo: <FaFileExcel />,
    disabled: true,
  },
]

export const DestinationList = [
  {
    id: "hubspot",
    name: "Hubspot",
    logo: <FaHubspot style={{ width: mobileMode ? "30px" : "45px" }} />,
    ui: "",
    disabled: false,
  },
  {
    id: "salesforce",
    name: "Salesforce",
    logo: <FaSalesforce style={{ width: mobileMode ? "30px" : "45px" }} />,
    ui: "",
    disabled: true,
  },
  {
    id: "airtable",
    name: "Airtable",
    logo: <SiAirtable style={{ width: mobileMode ? "30px" : "45px" }} />,
    disabled: true,
  },
  {
    id: "klaviyo",
    name: "Klaviyo",
    disabled: true,
  },
  {
    id: "facebook",
    name: "Facebook",
    logo: <FaFacebook style={{ width: mobileMode ? "30px" : "45px" }} />,
    disabled: true,
  },
  {
    id: "twitter_media",
    name: "Twitter Media",
    logo: <FaTwitter style={{ width: mobileMode ? "30px" : "45px" }} />,
    disabled: true,
  },
  {
    id: "linkedin",
    name: "LinkedIn",
    logo: <FaLinkedin style={{ width: mobileMode ? "30px" : "45px" }} />,
    disabled: true,
  },
  {
    id: "gmail",
    name: "Gmail",
    logo: <SiGmail style={{ width: mobileMode ? "30px" : "45px" }} />,
    disabled: true,
  },
  {
    id: "mailchimp",
    name: "Mail Chimp",
    logo: <FaMailchimp style={{ width: mobileMode ? "30px" : "45px" }} />,
    disabled: true,
  },
]



export const ServiceCard = memo((name, logo, ui) => {
  return (
    <div className="card">
      <p>{name}</p>
      <img src={logo} alt={name} />
      <img src={ui} alt={name} />
    </div>
  )
})