import { useState, useEffect } from "react"
import { suggestAudience } from "../../lib/.api"
import { handleGtag } from "../_utils/gtag"
import { TextArea, P } from "./__init__"


export const SuggestingAudience = ({ tabletMode, mobileMode, activeId, setActiveId }) => {
  const [audience, setAudience] = useState(null)
  const [isSelecting, setIsSelecting] = useState(false)
  const [error, setError] = useState(false)
  const [editMode, setEditMode] = useState(true)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    const execute = async () => {
      setIsSelecting(true)
      await suggestAudience()
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsSelecting(false); setError(true) }
          else return res.data.output
        })
        .then(res => { setAudience(res["audience"]); setIsSelecting(false) })
        .catch(err => { setIsSelecting(false); setError(true); setEditMode(true) })
    }

    if (audience === null && activeId === 1) execute()
  }, [audience, activeId])


  return (
    <div className="container Playground-sub-container">
      {isSelecting && <p style={{ color: "#414141" }}>...defining target audience...</p>}
      {error && <p className="GreyHover" style={{ fontSize: "12px" }}>Something went wrong. Input manually.</p>}

      {isSelecting === false &&
        <div className="container Playground-sub-container">
          {complete
            ?
            <P
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title="Target"
              value={audience}
            />
            :
            <TextArea
              editMode={editMode}
              setEditMode={setEditMode}
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title="Target"
              value={audience}
              setValue={setAudience}
            />
          }

          <p style={{ fontStyle: "italic" }}>Let's edit, or we'll build hypothesis to test on the audience</p>
          {!complete &&
            <button
              style={{ textDecoration: "underline" }}
              onClick={e => { setComplete(true); setActiveId(activeId + 1); handleGtag(e, "playground", activeId) }}>
              ok
            </button>
          }

        </div>
      }
    </div>
  )
}