import React from "react"
import { useParams } from "react-router-dom"
import { Redirect } from "../_utils/Redirect"


export const DestinationAuthSuccess = ({ redirectUrl = "/deploy" }) => {
  const { serviceName } = useParams()
  const code = window.location.search.replace("?code=", "")

  Redirect(redirectUrl, { code }, 3000)

  return (
    <div className="container content" style={{ minHeight: "500px", maxWidth: "800px", marginTop: "100px" }}>
      <p>{serviceName} - authorization success</p>
    </div>
  )
}
