import { useState, useEffect } from "react"
import { createMessage, predictPerformance } from "../../lib/.api"
import { TextArea, P, S } from "./__init__"
import { FaInfoCircle } from "react-icons/fa"

const CreateMessage = ({
  tabletMode, mobileMode, activeId, complete, setComplete, currentId, selectedValue, setSelectedValue,
}) => {
  const [message, setMessage] = useState(null)
  const [isSelecting, setIsSelecting] = useState(false)
  const [error, setError] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    const execute = async () => {
      setIsSelecting(true)
      await createMessage()
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsSelecting(false); setError(true) }
          else return res.data.output
        })
        .then(res => {
          setMessage(res.message); setComplete(true); setIsSelecting(false)
        })
        .catch(err => { setIsSelecting(false); setError(true); setEditMode(true) })
    }
    if (message === null && activeId === currentId) execute()
  }, [message, activeId, setComplete, currentId])


  return (
    <div>
      {isSelecting && <p style={{ color: "#414141" }}>...drafting messages...</p>}
      {error && <p></p>}

      {!isSelecting && editMode &&
        <div>
          <TextArea
            editMode={editMode}
            setEditMode={setEditMode}
            mobileMode={mobileMode}
            tabletMode={tabletMode}
            title={`Option ${currentId - 2}`}
            value={message}
            setValue={setSelectedValue}
          />
          <button onClick={() => setComplete(true)}>ok</button>
        </div>
      }
      {!isSelecting && !editMode && complete && activeId <= 7 &&
        <S
          mobileMode={mobileMode}
          tabletMode={tabletMode}
          title={`Option ${currentId - 2}`}
          value={message}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          disabled={Boolean(activeId >= 7)}
        />
      }
      {activeId > 7 &&
        <P
          mobileMode={mobileMode}
          tabletMode={tabletMode}
          title={`Option ${currentId - 2}`}
          value={error ? null : message}
        />
      }
    </div >
  )
}



const PredictPerformance = ({ tabletMode, mobileMode, activeId, currentId, canStart, setActiveId }) => {
  const [scores, setScores] = useState(null)
  const [isSelecting, setIsSelecting] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const execute = async () => {
      setIsSelecting(true)
      await predictPerformance()
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsSelecting(false); setError(true) }
          else return res.data.output
        })
        .then(res => { setScores(res); setIsSelecting(false); setActiveId(activeId + 1) })
        .catch(err => { setIsSelecting(false); setError(true); })
    }

    if (scores === null && activeId === currentId && canStart === true) execute()
  }, [scores, activeId, currentId, canStart, setActiveId])

  return (
    <div>
      {isSelecting && <p style={{ color: "#414141" }}>...estimating...</p>}
      {isSelecting === false && scores !== null && error === false &&
        <P
          mobileMode={mobileMode}
          tabletMode={tabletMode}
          title={<><FaInfoCircle />&nbsp;Performance score</>}
          value={
            <>
              <span style={{ fontWeight: 700 }}>Overall: </span>{scores["overall"] !== undefined && scores["overall"] !== 0 ? `${scores["overall"]}  /100` : "-"}
              <br />
              Fit to the brand tone: {scores["brand_tone"] !== 0 ? scores["brand_tone"] : "-"} /100
              <br />
              Fit to the target audience: {scores["audience"] !== 0 ? scores["audience"] : "-"} /100
              <br />
              Past track record: {scores["track_record"] !== 0 && scores["track_record"] !== undefined ? `${scores["track_record"]} /100` : "n.a."}
            </>
          }
        />
      }
    </div>
  )
}


export const MessageIdea = ({
  activeId, setActiveId, tabletMode, mobileMode, currentId, selectedValue, setSelectedValue
}) => {
  const [startPrediction, setStartPrediction] = useState(false)

  return (
    <div className="container Playground-sub-container">
      <CreateMessage
        currentId={currentId}
        activeId={activeId}
        tabletMode={tabletMode}
        mobileMode={mobileMode}
        complete={startPrediction}
        setComplete={setStartPrediction}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
      <PredictPerformance
        currentId={currentId}
        activeId={activeId}
        setActiveId={setActiveId}
        tabletMode={tabletMode}
        mobileMode={mobileMode}
        canStart={startPrediction}
      />
    </div>

  )
}
