import { useState, useEffect } from "react"
import { destinationAuth } from "../../lib/.api"

export const DestinationAuth = ({ activeId, setActiveId }) => {
  const [isSelecting, setIsSelecting] = useState(false)
  const [error, setError] = useState(false)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    const fetchCode = async () => {
      setIsSelecting(true)
      await destinationAuth()
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsSelecting(false); setError(true); setComplete(true); }
          else return res.data
        })
        .then(res => {
          const redirectUrl = res["redirect_url"]
          setComplete(true); setIsSelecting(false); setActiveId(activeId + 1);
          if (redirectUrl !== undefined || redirectUrl !== null) window.open(redirectUrl, "_blank")
        })
        .catch(err => { setError(true); setIsSelecting(false); setComplete(true) })
    }
    if (complete === false && activeId === 8) fetchCode()
  }, [complete, activeId, setActiveId])

  return (
    <div className="container Playground-sub-container">
      {isSelecting && <p style={{ color: "#414141" }}>...redirecting to login page...</p>}
      {error && <p style={{ color: "#414141" }}>Something went wrong.</p>}
    </div>
  )
}
