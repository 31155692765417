import { useState, useRef, useEffect } from "react"
import { handleGtag } from "../_utils/gtag"
import { TextArea, P } from "./__init__"
import { demoUrl } from "../_common"
import { apiRouteUrl } from "../../lib/.api"


export const FetchingProduct = ({
  tabletMode, mobileMode, goal, activeId, setActiveId, naturalLanguageInput, setFixed, selectedModelKey
}) => {
  let fetchedUrl = useRef(demoUrl)
  const [start, setStart] = useState(false)

  const [isAnalyzing, setIsAnalyzing] = useState(false)
  const [error, setError] = useState(null)
  const [product, setProduct] = useState(null)
  const [usp, setUsp] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    const execute = async () => {
      setError(false); setIsAnalyzing(true); setEditMode(false);

      await fetch(`${apiRouteUrl}/fetch-product`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url: fetchedUrl.current, goal, input: naturalLanguageInput, model: selectedModelKey })
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsAnalyzing(false); setError(400) }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => {
          setProduct(res["output"]["product"])
          setUsp(res["output"]["usp"])
          setEditMode(true)
          setFixed(true) // disabled natural lang input
        })
        .catch(err => { setIsAnalyzing(false); setError(true); setEditMode(true) })
      setIsAnalyzing(false)
    }

    if (start === true && activeId === 0) execute();
  }, [start, goal, activeId, naturalLanguageInput, setFixed, selectedModelKey])


  return (
    <div>
      <div className="container Playground-sub-container">

        <div style={{ display: "flex", margin: "10px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
          <p style={{ width: "240px" }}> Landing URL</p>
          <input
            className="input" type="url" name="url"
            placeholder={demoUrl}
            style={{ marginRight: "10px" }}
            onChange={e => fetchedUrl.current = e.target.value}
            onKeyDown={e => { if (e.key === "Enter") setStart(true) }}
            disabled={start}
          />
        </div>

        {isAnalyzing && <p style={{ color: "#414141" }}>...analyzing...</p>}
        {error && <p className="GreyHover" style={{ fontSize: "12px" }}>Invalid URL or input. Input mannually.</p>}
      </div>

      {start && isAnalyzing === false &&
        <div className="container Playground-sub-container">
          {complete
            ?
            <P
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title="Product overview"
              value={product}
            />
            :
            <TextArea
              editMode={editMode}
              setEditMode={setEditMode}
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title="Product overview"
              value={product}
              setValue={setProduct}
            />
          }

          {complete
            ?
            <P
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title="Selling points"
              value={usp}
            />
            :
            <TextArea
              editMode={editMode}
              setEditMode={setEditMode}
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title="Selling points"
              value={usp}
              setValue={setUsp}
            />
          }

          <p style={{ fontStyle: "italic" }}>Edit, or we'll start choosing target audience.</p>
          {!complete &&
            <button
              style={{ textDecoration: "underline" }}
              onClick={e => { setComplete(true); setActiveId(activeId + 1); handleGtag(e, "playground", activeId) }}>
              ok
            </button>
          }
        </div>
      }
    </div>
  )
}
