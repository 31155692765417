import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { GoInfo } from "react-icons/go"
import { NewsBar } from "./_utils/Elements"
import { handleGtag } from "./_utils/gtag"
import { usecaseList, modelList } from "./_common"
import { ModelButton } from "./LLM"


export const Home = ({ tabletMode, mobileMode }) => {
  const navigate = useNavigate()
  const [displayExp, setDisplayExp] = useState(null)
  const [goalId, setGoalId] = useState(null)
  const [selectedModelId, setSelectedModelId] = useState(null)
  const [modelStartId, setModelStartId] = useState(0)
  const defaultNum = Math.floor(window.innerWidth / 300)

  const isSmallScreen = mobileMode || tabletMode
  const containerStyle = { maxWidth: mobileMode ? "100%" : tabletMode ? "700px" : "1200px", display: "flex", flexDirection: "column", alignItems: "center" }

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href="https://versi0n.io" />
        <title>Home | version</title>
      </Helmet>

      <div className="container content" style={{ maxHeight: isSmallScreen ? undefined : "700px", padding: isSmallScreen ? "50px 20px" : "100px 50px", ...containerStyle }}>

        <NewsBar
          newsTitle={"Currently on the Accelerator Program with AWS"}
          link={"https://www.linkedin.com/in/k-i-i/"}
          fontSize={isSmallScreen ? "12px" : "16px"}
        />

        <h1 style={{ lineHeight: 1.5, fontWeight: 700, padding: "0 0 10px 0" }} itemProp="description">
          Acquire <span style={{ background: "-webkit-linear-gradient(left, #00d1b2, #169d87)", backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>loyal customers</span> with
          <br />
          hyper-personalized messaging
        </h1>

        <h2 style={{ lineHeight: 1.1, padding: "30px 0" }}>
          Build messaging workflows for:
        </h2>

        <div style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", alignItems: isSmallScreen ? "center" : undefined, height: isSmallScreen ? undefined : "200px" }}>
          {usecaseList.map((item, i) => {
            const { title, expShort } = item
            return (
              <div key={i} style={{ dispaly: "flex", flexDirection: "column", alignItems: "center", minWidth: "280px", maxWidth: "280px", marginBottom: "16px" }}>
                <button
                  className={goalId === i ? "Hover button is-primary" : "Hover button is-primary is-outlined"}
                  aria-label="button"
                  style={{ margin: "15px 15px 5px", display: "block", height: "60px", width: "240px", maxWidth: "240px", }}
                  onClick={e => { setGoalId(i); handleGtag(e, "home"); navigate("/playground", { state: { goalId: i } }) }}
                  onMouseEnter={() => setDisplayExp(i)} onMouseLeave={() => setDisplayExp(null)}
                >
                  {title}
                </button>
                {displayExp === i &&
                  <p className="GreyHover" style={{ fontSize: "12px", margin: "0 20px" }}>
                    <GoInfo />&nbsp;{expShort}
                  </p>
                }
              </div>
            )
          })}
        </div>
      </div>

      <div className="container content" style={{ ...containerStyle }}>
        <p style={{ fontSize: "18px", fontWeight: 700 }}>AI agents on:</p>
      </div>

      <hr style={{ margin: 0 }} />

      <div style={{ display: "flex", width: "100%", justifyContent: "center", maxHeight: mobileMode ? undefined : "100px", minHeight: "100px" }}>
        <button aria-label="previous" onClick={e => {
          setModelStartId(
            modelStartId - defaultNum < 0 && defaultNum === 1
              ? modelList.length - 1
              : modelStartId - defaultNum < 0
                ? 0
                : modelStartId - defaultNum
          )
          handleGtag(e, "home", "model back")
        }}>
          <IoIosArrowBack />
        </button>

        {modelList
          .sort((a, b) => Number(b.isNew) - Number(a.isNew))
          .filter(item => (modelList.indexOf(item) >= modelStartId && modelList.indexOf(item) < Number(modelStartId) + defaultNum))
          .map((item, i) =>
            <ModelButton
              key={i}
              id={modelList.indexOf(item)}
              model={item}
              selectedModelId={selectedModelId}
              setSelectedModelId={setSelectedModelId}
            />
          )
        }

        <button aria-label="next" onClick={e => {
          setModelStartId(modelStartId + defaultNum > modelList.length - 1 ? 0 : modelStartId + defaultNum)
          handleGtag(e, "model", "model proceed")
        }}>
          <IoIosArrowForward />
        </button>
      </div>
    </HelmetProvider>
  )
}
