import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import "./main.scss"


var mobileMode = Boolean(window.innerWidth <= 430)
var tabletMode = Boolean(window.innerWidth > 430 && window.innerWidth <= 830)
// var baseUrl = process.env.REACT_APP_DEV_MODE === "production" ? "https://versi0n.io" : "https://beta.versi0n.io"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<App tabletMode={tabletMode} mobileMode={mobileMode} />)
