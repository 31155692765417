import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { deployWorkflow } from "../../lib/.api"


export const Deploy = () => {
  const location = useLocation()
  let { code } = location.state
  const [isDeploying, setIsDeploying] = useState(false)
  const [error, setError] = useState(false)
  const [complete, setComplete] = useState(false)
  const [res, setRes] = useState(null)

  useEffect(() => {
    const deploy = async () => {
      setIsDeploying(true)
      await deployWorkflow({ code })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsDeploying(false); setError(true); setComplete(true); }
          else return res.data
        })
        .then(res => { setRes(res); setIsDeploying(false); setComplete(true) })
        .catch(err => { setError(true); setIsDeploying(false); setComplete(true) })
    }
    if (!complete && res === null) deploy()
  }, [complete, res, code])

  // add 1 more fetch- agents

  if (!code || code === undefined) return (<p>Deployment not authorized.</p>)
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href="https://versi0n.io/deploy" />
        <title>Deploy messaging workflows | version</title>
      </Helmet>

      <div className="container content" style={{ minHeight: "500px", maxWidth: "800px", marginTop: "100px" }}>
        <p><span style={{ fontWeight: 700 }}>Destination:</span>&nbsp;Hubspot</p>
        {process.env.REACT_APP_DEV_MODE === "dev" && <p>Code: {code}</p>}

        <br />

        {isDeploying && <p>...Deploying...</p>}
        {error && <p>Something went wrong.</p>}
        {complete && res !== null &&
          <div>
            <p style={{ fontWeight: 700 }}>Agent teams worked for the workflow creation:</p>
            <ul>
              {("agents" in res && res["agents"]) && res["agents"].map((item, i) => <li key={i}>{item}</li>)}
            </ul>

            <br />

            <p style={{ fontWeight: 700 }}>Team leader agent assigned to autopilot:</p>
            <ul>
              {("leader" in res && res["leader"]) && <li>{res["leader"]}</li>}
            </ul>

            <div className="buttons">
              <button className="button is-primary">Autopilot</button>
            </div>
          </div>
        }
      </div>
    </HelmetProvider>
  )
}