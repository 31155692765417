import gtag from "ga-gtag"
import { returnSelfUserId } from "../../lib/.auth"


export const handleGtag = (e, componentName = "home", object = null, ...props) => {
  var userId = returnSelfUserId()
  const user = userId === undefined ? "annoymous" : userId
  const eventType = e && e.type ? e.type : "click"
  const objectName = object !== null ? object : e && e.target ? String(e.target.innerHTML) : null
  const event_name = `${eventType}: ${objectName} at ${componentName}`
  gtag("event", event_name, { user, })
}
