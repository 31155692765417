import React from "react"
import { useNavigate } from "react-router-dom"
import { handleGtag } from "./_utils/gtag"
import { modelList } from "./_common"


export const ModelButton = ({ id, model, selectedModelId, setSelectedModelId, navigateTo = "/playground", ...props }) => {
  const { name, logo, exp, isNew } = model
  const navigate = useNavigate()

  return (
    <button
      style={{ display: "flex", justifyContent: "center", margin: "5px", padding: "10px", minWidth: "240px", maxWidth: "240px", border: selectedModelId === id ? "#191b1b solid 1px" : undefined, borderRadius: "10px" }}
      aria-label="model"
      onClick={e => {
        setSelectedModelId(selectedModelId === null ? id : null)
        handleGtag(e, "model button", name)
        if (navigateTo) navigate(navigateTo, { state: { modelId: id, ...props } })
      }}
    >
      <img src={logo} alt={name.slice(0, 2)} width={50} height={50} style={{ marginRight: "10px" }} />
      <div style={{ textAlign: "left", minWidth: "160px", maxWidth: "160px" }}>
        <p style={{ fontSize: "14px", fontWeight: 700 }}>{name} {isNew && <span className="tag is-black">New</span>}</p>
        <p style={{ fontSize: "12px", marginTop: "5px" }}>{exp}</p>
      </div>
    </button >
  )
}


export const ModelIndex = ({ styleProps, selectedModelId, setSelectedModelId }) => {
  // const [modelId, setModelId] = useState(idToFilter)

  return (
    <div style={{ ...styleProps }}>
      {selectedModelId !== null
        ?
        <ModelButton
          id={selectedModelId}
          model={modelList[selectedModelId]}
          navigateTo={null}
          selectedModelId={selectedModelId}
          setSelectedModelId={setSelectedModelId}
        />
        : modelList
          .sort((a, b) => Number(b.isNew) - Number(a.isNew))
          .map((item, i) =>
            <ModelButton
              key={i}
              id={modelList.indexOf(item)}
              model={item}
              navigateTo={null}
              selectedModelId={selectedModelId}
              setSelectedModelId={setSelectedModelId}
            />
          )
      }
    </div>
  )
}
