import React from "react"
import { CiEdit } from "react-icons/ci"
import { handleGtag } from "../_utils/gtag"


export const TextArea = ({ editMode, setEditMode, mobileMode, tabletMode, title, value, setValue, ...props }) => {
  return (
    <div>
      <div style={{ display: "flex", margin: "10px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
        <p style={{ width: "240px" }}>{title}: </p>
        <textarea
          className="textarea"
          placeholder={value}
          defaultValue={value}
          row={3}
          style={{
            padding: "10px", minHeight: "120px", border: "solid 1px #dedede", borderRadius: "5px", display: "block", wordBreak: "auto-phrase", maxWidth: (mobileMode || tabletMode) ? "100%" : "600px", minWidth: (mobileMode || tabletMode) ? "100%" : "400px"
          }}
          onChange={e => { e.preventDefault(); setValue(e.target.value) }}
          aria-label="product"
          disabled={editMode === false}
          onKeyDown={e => { if (e.key === "Enter") e.target.disabled = true }}
        />
      </div>

      <button
        onClick={e => { setEditMode(!editMode); handleGtag(e, "playground", value) }}
        style={{ position: "relative", bottom: "50px", right: "-90%" }}
      >
        <CiEdit style={{ width: "20px" }} />
      </button>
    </div>
  )
}


export const P = ({ mobileMode, tabletMode, title, value, selectMode = false }) => {
  return (
    <div>
      <div style={{ display: "flex", margin: "10px 0", padding: "0 10px", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
        <p style={{ width: "200px", minWidth: "200px" }}>{title}: </p>
        <div style={{
          padding: "10px", minHeight: "120px", display: "block", wordBreak: "auto-phrase", maxWidth: (mobileMode || tabletMode) ? "100%" : "600px", minWidth: (mobileMode || tabletMode) ? "100%" : "600px"
        }}>
          <p>{value}</p>
        </div>
      </div>
    </div>
  )
}


export const S = ({ mobileMode, tabletMode, title, value, setSelectedValue, selectedValue, disabled = false }) => {
  return (
    <button onClick={() => setSelectedValue(selectedValue ? null : value)} style={{ textAlign: "left" }} disabled={disabled}>
      <div style={{ display: "flex", margin: "10px 0", padding: "10px", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined, border: selectedValue === value ? "2px solid #00d1b2" : undefined }}>
        <p style={{ width: "240px" }}>{title}: </p>
        <div style={{
          padding: "10px", minHeight: "120px", display: "block", wordBreak: "auto-phrase", maxWidth: (mobileMode || tabletMode) ? "100%" : "600px", minWidth: (mobileMode || tabletMode) ? "100%" : "600px"
        }}>
          <p>{value}</p>
        </div>
      </div>
    </button>
  )
}
