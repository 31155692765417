import { useState, useEffect } from "react"
import { apiRouteUrl } from "../../lib/.api"
import { handleGtag } from "../_utils/gtag"


export const Workflow = ({ activeId, setActiveId, selectedMessage, destination }) => {
  const [workflow, setWorkflow] = useState(null)
  const [isSelecting, setIsSelecting] = useState(false)
  const [error, setError] = useState(false)
  // const [editMode, setEditMode] = useState(true)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    const execute = async () => {
      setIsSelecting(true)
      await fetch(`${apiRouteUrl}/build-workflow`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: selectedMessage, destination: destination })
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsSelecting(false); setError(true) }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => { setWorkflow(res["output"]); setIsSelecting(false) })
        .catch(err => { setIsSelecting(false); setError(true) })
    }

    if (workflow === null && activeId === 7) execute();
  }, [workflow, activeId, selectedMessage, destination])

  return (
    <div className="container Playground-sub-container">
      {isSelecting && <p style={{ color: "#414141" }}>...designing workflow...</p>}
      {isSelecting === false && !error && workflow !== null &&
        <div>
          <p style={{ fontWeight: 700 }}>Next best action / message to send:</p>
          <p>{"next_message" in workflow ? workflow["next_message"] : null}</p>
          <br />
          <p>
            <span style={{ fontWeight: 700 }}>Best interval: </span>{"interval" in workflow ? workflow["interval"] : null} days
          </p>
          <br />
          <p>
            <span style={{ fontWeight: 700 }}>Conditions to consider: </span>{"condition" in workflow ? workflow["condition"] : null}
          </p>
        </div>
      }
      <div className="container Playground-sub-container">
        {!complete && workflow !== null &&
          <button
            style={{ textDecoration: "underline", fontStyle: "italic" }}
            onClick={e => { setComplete(true); setActiveId(activeId + 1); handleGtag(e, "playground", activeId) }}
          >
            {destination ? `Deploy the workflow on ${destination}` : "Deploy"}
          </button>
        }
      </div>
    </div>
  )
}
