export const setAuthItems = (token, userId, username, companyId) => {
  window.localStorage.setItem("token", token)
  window.localStorage.setItem("userId")
  window.localStorage.setItem("username", username)
  window.localStorage.setItem("companyId", companyId)
}

export const setItem = (itemName, value) => { window.localStorage.setItem(itemName, value) }
export const getItem = itemName => { return (window.localStorage.getItem(itemName) ? window.localStorage.getItem(itemName) : null) }

export const getToken = () => { return (window.localStorage.getItem("token")) } // dict: token key and user id
export const logout = () => {
  for (var key in window.localStorage) {
    window.localStorage.removeItem(key)
  }
}
export const returnSelfUserId = () => { return getItem("userId") }
// return getPayload().id




// <--- legacy --->
export const getPayload = () => {
  const token = getToken()
  if (!token) return false

  const parts = token.split(".")
  if (parts.length < 3) return false

  return JSON.parse(window.atob(parts[1]))
}

export const isAuthorizedSelf = id => {
  const userId = getPayload().id
  return Number(id) === userId // typeof id === string vs userId === num
}

export const isAuthenticated = () => {
  const payload = getPayload()
  if (!payload) return false
  const now = Math.round(Date.now() / 1000)
  return now < payload.exp
}