import { useState } from "react"
import { useLocation } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { ModelIndex } from "./LLM"
import { SignUp } from "./auth/Auth"
import { BackToPrevPage, Modal } from "./_utils/Elements"
import { handleGtag } from "./_utils/gtag"
import { usecaseList, modelList } from "./_common"
import { FetchingProduct } from "./playground/FetchingProduct"
import { SuggestingAudience } from "./playground/SuggestingAudience"
import { BuildingHypo } from "./playground/BuildingHypo"
import { MessageIdea } from "./playground/MessageIdea"
import { Workflow } from "./playground/Workflow"
import { DestinationList, DataPipelines } from "./services/_list"
import { DestinationAuth } from "./playground/DestinationAuth"

//! UPDATEME - add a credit per day, for retention - start with dummy contact info, add improve x2 - chat with user

export const Playground = ({ tabletMode, mobileMode }) => {
  const mainContainerStyleProps = { width: mobileMode ? "100%" : tabletMode ? "500px" : "800px", minWidth: mobileMode ? "100%" : tabletMode ? "500px" : "640px", maxWidth: mobileMode ? "100%" : tabletMode ? "500px" : "840px", minHeight: mobileMode ? "100vh" : undefined, paddingBottom: "100px" }

  const location = useLocation()
  const [activeId, setActiveId] = useState(0)

  // goal
  const goalId = location.state && location.state.goalId !== undefined ? location.state.goalId : null
  const goal = goalId === null
    ? `${usecaseList[0]["title"]} - ${usecaseList[0]["expShort"]}`
    : `${usecaseList[goalId].title} - ${usecaseList[goalId].expShort}`
  const [selectedGoalId, setSelectedGoalId] = useState(goalId)

  // base model
  const initModelId = location.state && location.state.modelId !== undefined && location.state.modelId !== null
    ? location.state.modelId
    : null
  const [selectedModelId, setSelectedModelId] = useState(initModelId)

  // destination service, data pipeline
  const [dataPipeline, setDataPipeline] = useState(null)
  const [destination, setDestination] = useState(null)

  // natural language request - take 
  const [naturalLangInput, setNaturalLangInput] = useState(null)

  // fixed pre-requist conditions: llm, natural lang input, goal, destination service
  const [fixed, setFixed] = useState(false)

  // message value to be selected
  const [selectedValue, setSelectedValue] = useState(null)


  // // handle daily quota: 3
  // let quota = useRef(0)
  // console.log(window.localStorage)


  // handle cta
  const [displayAuthModal, setDisplayAuthModal] = useState(false)
  const [displayAccountConnectionModal, setDisplayAccountConnectionModal] = useState(false)


  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href="https://versi0n.io/playground" />
        <title>Playground | version</title>
      </Helmet>

      <hr style={{ margin: "5px 0 0", minWidth: "100%", border: "0.3px solid #fafafa", height: "0.5px" }} />

      <h1 style={{ display: "none" }}>Playground</h1>

      <div style={{ minWidth: "100%", display: "flex", flexDirection: mobileMode ? "column" : "row" }}>

        <div className="Playground-side-menu">
          <BackToPrevPage style={{ fontSize: "12px" }} pageName={"home"} />

          <div style={{ padding: mobileMode || tabletMode ? "5px" : "10px", fontSize: mobileMode ? "12px" : undefined }}>
            <p style={{ fontWeight: 700 }}>Goal:</p>
            {goalId === null
              ?
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {usecaseList.map((item, i) => {
                  return (
                    <button
                      key={i}
                      className={selectedGoalId === i ? "button is-primary" : "button"}
                      style={{ width: mobileMode ? "160px" : tabletMode ? "240px" : "280px", margin: "5px 0", fontSize: mobileMode ? "12px" : undefined }}
                      onClick={e => { handleGtag(e, "playground"); setSelectedGoalId(i) }}
                    >
                      {item.title}
                    </button>
                  )
                })}
              </div>
              :
              <p>{goal}</p>
            }
          </div>

          <div style={{ padding: mobileMode || tabletMode ? "5px" : "10px", marginTop: "10px" }}>
            <p style={{ fontWeight: 700 }}>LLM to run the main AI agent:</p>
            <ModelIndex
              styleProps={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              selectedModelId={selectedModelId}
              setSelectedModelId={setSelectedModelId}
            />
          </div>
        </div>

        <div className="container" style={{ ...mainContainerStyleProps }}>

          <div className="container Playground-sub-container">
            <div style={{ display: "flex", margin: "10px 0", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
              <p style={{ minWidth: "160px" }}>Data pipeline:</p>
              <div className="buttons">
                {dataPipeline !== "skipped" && DataPipelines
                  .filter(item => dataPipeline !== null ? DataPipelines.indexOf(item) === dataPipeline : item)
                  .map((item, i) =>
                    <button
                      key={i}
                      className={dataPipeline === i ? "button is-dark is-small" : "button is-small"}
                      style={{ width: "80px", margin: "5px 0" }}
                      onClick={e => {
                        handleGtag(e, "playground"); setDataPipeline(dataPipeline === null ? DataPipelines.indexOf(item) : null)
                      }}
                      disabled={item.disabled}
                    >
                      {item.name}
                    </button>
                  )}
                {(dataPipeline === "skipped" || dataPipeline === null) &&
                  <button disabled className="button is-small" onClick={() => setDataPipeline(dataPipeline === "skipped" ? null : "skipped")}>
                    skip
                  </button>
                }
              </div>
            </div>

            <div style={{ margin: "10px 0" }}>
              <p style={{ minWidth: "160px" }}>Destination:</p>
              {/* <p style={{ fontSize: "12px" }}>
                Choose a platform you want to launch the workflow. or&nbsp;
                <span>
                  {(destination === "skipped" || destination === null) &&
                    <button
                      style={{ fontSize: "12px", textDecoration: "underline", border: "none" }}
                      onClick={() => setDestination(destination === "skipped" ? null : "skipped")}>
                      skip
                    </button>
                  }
                </span>
              </p> */}
              <div className="buttons">
                {destination !== "skipped" && DestinationList
                  .filter(item => destination !== null ? DestinationList.indexOf(item) === destination : item)
                  .sort((a, b) => Number(a.disabled) - Number(b.disabled))
                  .map((item, i) =>
                    <button
                      key={i}
                      style={{ width: mobileMode ? "50px" : "70px", maxWidth: mobileMode ? "50px" : "70px", padding: mobileMode ? "5px" : "10px", margin: mobileMode ? "1px 0" : "5px 0", borderRadius: "10px", color: item.disabled ? "hsl(0, 0%, 71%)" : undefined }}
                      onClick={e => {
                        handleGtag(e, "playground");
                        setDestination(destination === null || destination === "skipped" ? DestinationList.indexOf(item) : null)
                      }}
                      disabled={item.disabled || fixed || DestinationList.indexOf(item) !== 0}
                    >
                      {item.logo ? item.logo : item.name}
                    </button>
                  )}
              </div>
            </div>
          </div>

          <div className="container Playground-sub-container">
            <p>Specific request if any. We'll run analysis later.</p>
            {fixed === true
              ?
              <p>{naturalLangInput}</p>
              :
              <textarea
                className="textarea"
                onChange={e => setNaturalLangInput(e.target.value)}
                placeholder="Outbound objective, KPI, product overview, target audience etc."
              />
            }
          </div>

          <FetchingProduct
            goal={goal}
            activeId={activeId}
            setActiveId={setActiveId}
            tabletMode={tabletMode}
            mobileMode={mobileMode}
            naturalLangInput={naturalLangInput}
            setFixed={setFixed}
            selectedModelKey={selectedModelId !== null ? modelList[selectedModelId]["key"] : ""}
          />

          {activeId >= 1 &&
            <SuggestingAudience
              activeId={activeId}
              setActiveId={setActiveId}
              tabletMode={tabletMode}
              mobileMode={mobileMode}
            />
          }

          {activeId >= 2 &&
            <BuildingHypo
              activeId={activeId}
              setActiveId={setActiveId}
              tabletMode={tabletMode}
              mobileMode={mobileMode}
            />
          }

          {activeId >= 3 &&
            <div>
              <MessageIdea
                currentId={3}
                activeId={activeId}
                setActiveId={setActiveId}
                tabletMode={tabletMode}
                mobileMode={mobileMode}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
              <hr style={{ width: "80%", margin: 0 }} />
            </div>
          }

          {activeId >= 4 &&
            <div>
              <MessageIdea
                currentId={4}
                activeId={activeId}
                setActiveId={setActiveId}
                tabletMode={tabletMode}
                mobileMode={mobileMode}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
              <hr style={{ width: "80%", margin: 0 }} />
            </div>
          }

          {activeId >= 5 &&
            <MessageIdea
              currentId={5}
              activeId={activeId}
              setActiveId={setActiveId}
              tabletMode={tabletMode}
              mobileMode={mobileMode}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          }

          {activeId >= 5 &&
            <div className="container Playground-sub-container">
              {selectedValue
                ?
                <div>
                  <p><span style={{ fontWeight: 700 }}>Message selected: </span>{selectedValue}</p>
                  {activeId >= 5 &&
                    <button
                      style={{ textDecoration: "underline", }}
                      onClick={e => { setActiveId(activeId + 1); handleGtag(e, "playground", activeId) }}
                      disabled={Boolean(activeId >= 7)}
                    >
                      ok
                    </button>
                  }
                </div>
                : <p>Select one to deploy.</p>
              }
            </div>
          }

          {activeId >= 7 &&
            <Workflow
              activeId={activeId}
              setActiveId={setActiveId}
              selectedMessage={selectedValue}
              destination={destination !== null && destination !== "skipped" ? DestinationList[destination]["id"] : null}
            />
          }

          {activeId >= 8 &&
            <DestinationAuth
              activeId={activeId}
              setActiveId={setActiveId}
            />
          }
        </div>

      </div>
      <Modal
        displayModal={displayAuthModal} setDisplayModal={setDisplayAuthModal}
        title="Sign up and save"
        children={<SignUp hasHelmet={false} />}
        width={mobileMode ? "360px" : "500px"}
      />
      <Modal //! UPDATEME - modal to connect 3rd party account
        displayModal={displayAccountConnectionModal} setDisplayModal={setDisplayAccountConnectionModal}
        title="Connect your account"
        children={<div><p>Hubspot</p><p>Facebook</p><p>Salesforce</p></div>}
        width={mobileMode ? "360px" : "500px"}
      />

    </HelmetProvider >
  )
}