import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { Home } from "./components/Home"
import { Playground } from "./components/Playground"
import { ContactUs } from "./components/ContactUs"
import { SignUp, SignIn } from "./components/auth/Auth"
import { Message } from "./components/_utils/Message"
import { MaintenanceMode } from "./components/MaintenanceMode"
import { DestinationAuthSuccess } from "./components/services/_OAuth2"
import { Deploy } from "./components/services/Deploy"


export const App = ({ mobileMode, tabletMode }) => {
  const maintenanceMode = Boolean(process.env.REACT_APP_MAINTENANCE_MODE === "true" && process.env.REACT_APP_DEV_MODE !== "dev")
  const dt = process.env.REACT_APP_DT

  return (
    <BrowserRouter>
      <Header tabletMode={tabletMode} mobileMode={mobileMode} maintenanceMode={maintenanceMode} />
      <Routes>
        <Route path="*" element={<Message message="Page not found" />} />
        <Route exact path="/" element={maintenanceMode ? <MaintenanceMode dt={dt} /> : <Home tabletMode={tabletMode} mobileMode={mobileMode} />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route exact path="/sign-in" element={<SignIn />} />
        <Route exact path="/contact-us" element={<ContactUs tabletMode={tabletMode} mobileMode={mobileMode} />} />
        <Route path="playground">
          <Route path="" element={<Playground tabletMode={tabletMode} mobileMode={mobileMode} />} />
        </Route>
        <Route path=":serviceName?/success*" element={<DestinationAuthSuccess />} />
        <Route path="/deploy" element={<Deploy />} />
      </Routes>
      <Footer tabletMode={tabletMode} mobileMode={mobileMode} />
    </BrowserRouter >
  )
}
